<template>
  <div style="width: 100%;height: 100%;background: #F2F2F2; overflow: auto;" id="all">
    <div v-if="payState" style="height: 100%">
      <el-table
          v-loading="loading"
          style="width: 100%;height:100%">
      </el-table>
    </div>
    <div id="putBox" v-else>
      <div id="toOrder" @click="pathgoLookOrder">维修订单</div>
      <div id="priceInformation">
        <div class="putPart" style="border-top: 1px #F2F2F2 solid">
          <div style="margin-left: 20px;color: black;font-weight: bolder">填写保修信息</div>
        </div>
        <div class="putPart" >
          <van-field
              required
              style="padding-left: 20px;"
              readonly
              clickable
              name="picker"
              :value="value"
              label="产品型号"
              placeholder="请选择产品型号"
              @click="showProductPicker = true"
          />
        </div>
        <div class="putPart">
          <van-field
              required
              style="padding-left: 20px"
              readonly
              clickable
              name="picker"
              :value="value1"
              label="故障类型"
              placeholder="请选择故障类型（可多选）"
              @click="doShowFixPicker()"
          />
        </div>
        <div class="putPart">
          <van-field
              required
              style="padding-left: 20px"
              readonly
              clickable
              name="picker"
              :value="value2"
              label="购买渠道"
              placeholder="如：抖音、京东"
              @click="showBuyPicker = true"
          />
        </div>
        <div class="putPart" v-if="storePutJudge">
          <van-form validate-first>
            <van-field
                inputmode="numeric"
                required
                @blur="onBlur()"
                style="padding-left: 20px"
                v-model="buyWay"
                name="填写渠道"
                label="填写渠道"
                placeholder="请输入购买的渠道"
                :rules="[{ required: true, message: '请填写其他渠道的信息',trigger:'onBlur' }]"
            />
          </van-form>
        </div>
        <div class="putPart">
          <van-form validate-first>
            <van-field
                inputmode="numeric"
                :required='true'
                @blur="onBlur()"
                style="padding-left: 20px"
                v-model="orderNum"
                name="订单编号"
                label="订单编号"
                placeholder="购买平台可查找"
                :rules="[{ required: true, message: '请填写订单信息',trigger:'onBlur' }]"
            />
          </van-form>
        </div>
        <div class="putPart">
          <van-form validate-first>
            <van-field
                inputmode="numeric"
                :required='true'
                @blur="onBlur()"
                style="padding-left: 20px"
                v-model="sendNum"
                name="快递单号"
                label="快递单号"
                placeholder="购买平台可查找"
                :rules="[{ required: true, message: '请填写快递单号信息',trigger:'onBlur' }]"
            />
          </van-form>
        </div>
        <div class="putPart" v-if="Imei">
          <van-field
              inputmode="numeric"
              required
              @blur="onBlur()"
              style="padding-left: 20px"
              v-model="ImeiNum"
              name="Imei号"
              label="Imei号"
              placeholder="请输入儿童手表的Imei号"
          />
        </div>
        <div class="putPart">
          <van-field
              style="padding-left: 20px"
              disabled
              v-model="orderPrices"
              name="维修费用"
              label="维修费用"
              placeholder="0.00元"
          />
        </div>
      </div>
      <div id="address">
        <div class="putPart" style="border-top: 1px #F2F2F2 solid">
          <div style="margin-left: 10px;color:black;font-weight: bolder"><span style="color: red;margin-right: 0.1rem">*</span>填写收货地址</div>
        </div>
        <div class="putPart" >
          <van-form validate-first>
            <van-field
                @blur="onBlur()"
                style="padding-left: 20px"
                v-model="fixAddress"
                name="收货人"
                label="收货人"
                placeholder="请填写收货人地址"
                :rules="[{ required: true, message: '请填写收货人地址',trigger:'onBlur'}]"
            />
          </van-form>
        </div>
        <div class="putPart">
          <van-form validate-first>
            <van-field
                inputmode="numeric"
                maxlength="11"
                @blur="onBlur()"
                style="padding-left: 20px"
                @input="inputphoneNum"
                v-model="phoneNum"
                name="手机号码"
                label="手机号码"
                placeholder="请填写收货人手机号码"
                :rules="[
                  { required: true, message: '手机号码不能为空！',trigger: 'onBlur' },
                  {validator,message: '请输入正确格式的手机号码',trigger: 'onBlur'}]"
            />
          </van-form>
        </div>
        <div class="putPart">
          <van-field
              style="padding-left: 20px"
              readonly
              clickable
              name="area"
              :value="value3"
              label="地区选择"
              placeholder="点击选择省市区"
              @click="showArea = true"
          />
        </div>
        <div class="putPart">
          <van-form validate-first>
            <van-field
                @blur="onBlur()"
                style="padding-left: 20px"
                v-model="longAddress"
                name="详细地址"
                label="详细地址"
                placeholder="例如**街道**号**"
                :rules="[{ required: true, message: '请填写详细地址',trigger:'onBlur' }]"
            />
          </van-form>
        </div>
      </div>
      <van-button type="info" style="
                  font-size:15px;
                  margin-top:5%;
                  margin-bottom:5%;
                  width: 60%;
                  border-radius: 1rem;
                  height: 1.2rem;"
                  @click="gotoPay()">
        去支付
      </van-button>
      <van-popup v-model="showProductPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="column"
            @confirm="onConfirm"
            @cancel="showProductPicker = false"
        />
      </van-popup>
      <van-popup v-model="showBuyPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns2"
            @confirm="onConfirm2"
            @cancel="showBuyPicker = false"
        />
      </van-popup>
      <van-popup v-model="showArea" position="bottom">
        <van-area
            :area-list="areaList"
            @confirm="onConfirm3"
            @cancel="showArea = false"
        />
      </van-popup>
      <van-popup
          v-model="showFixPicker"
          position="bottom"
      >
        <van-area
            item-height="0"
            @confirm="onConfirm1"
            @cancel="showFixPicker = false"
        />
        <van-search
            style="padding: 2%;color: black"
            v-model="searchValue"
            show-action
            placeholder="请输入搜索关键词"
            @input="inputSearchValue"
            @search="onSearch"
            @cancel="onCancel"
        ></van-search>
        <van-checkbox-group
            style="margin-left: 2%"
            v-model="checkedValue"
            ref="checkboxGroup"
        >
          <van-cell-group>
            <van-cell
                style="padding: 3%;font-size: 0.4rem"
                v-for="(item, index) in searchColumns1"
                clickable
                :key="index"
                :title="item"
            >
              <template #right-icon>
                <van-checkbox :name="item" ref="checkboxes" shape="square" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </van-popup>
    </div>
  </div>
</template>

<script>
// import WeixinJSBridge from 'weixin-jsapi';
import _ from 'lodash';
import axios from 'axios';
import {areaList} from '@vant/area-data';
import { Toast } from 'vant';
export default {
  name: "putInformation",
  created() {
    this.getAlldata()
  },
  mounted() {
  },
  data() {
    return {
      payState:false,
      loading:true,
      startState:"0",
      payCount:0,
      PayParam:{
        appId: "",
        timeStamp: "",
        nonceStr: "",
        package: "",
        signType: "RSA",
        paySign:"",
      },
      Imei:false,
      buyWay:'',
      ImeiNum:'',
      wxNo:'',
      OpenIdToken:'',
      allDate:[],
      QueryPrice:[],
      searchValue:'',
      checkedValue:[],
      orderNum:'',
      sendNum:'',
      orderPrices:0,
      fixAddress:'',
      phoneNum:'',
      longAddress:'',
      value: '',
      productId:'',
      value1: '',
      problemId:'',
      value2: '',
      value3: '',
      Clock: [
        {
          "id":1,
          "name":"儿童手表V2"
        },
        {
          "id":2,
          "name":"儿童手表V3"
        },
        {
          "id":3,
          "name":"儿童手表V5"
        },
        {
          "id":4,
          "name":"儿童手表D1"},
        {
          "id":5,
          "name":"儿童手表D1 PRO"
        },
        {
          "id":6,
          "name":"成人智能手表Watch FLY"
        }
      ],
      column:['儿童手表V2','儿童手表V3','儿童手表V5','儿童手表D1','儿童手表D1 PRO','成人智能手表Watch FLY'],
      showProductPicker: false,
      columns1: [],
      searchColumns1:[],
      showFixPicker: false,
      columns2: ['抖音','京东','淘宝','拼多多','其他'],
      showBuyPicker: false,
      areaList,
      showArea: false,
      listQuery:{
        productId:'',
        faultTypeIds:'',
        chanel:'',
        amount:'',
        receiveName:'',
        receivePhone:'',
        receiveRegion:'',
        receiveAddress:''
      },
      storePutJudge:false,
    };
  },
  methods: {
    validator(val){
      return (/^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/.test(val))||(/^(\+?(\d{1,3}(-| ))?(\()?\d{3,4}\)?)?( x\d{1,5})?$/.test(val))
    },
    inputphoneNum(){
      this.phoneNum = event.target.value.replace(/\D/, '')
    },
    getAlldata(){
      const token = localStorage.getItem('token');
      const headers = {
        'wxToken': token // 替换成你的wxtoken值
      };
      axios.get('https://wdt.homwee.net/prod-api/wx/menu', {headers})
          .then(response => {
            console.log(response)
            this.allDate=response.data.data
            // 请求成功时的处理
            console.log(response.data.data);
          })
          .catch(error => {
            console.error(error);
            const status = error.response.status
            console.log(status)
            if(status===402){
              alert("登录信息过期，请重新登录！")
              this.$router.push('/logIn')
            }
          });
    },
    onBridgeReady() {
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": this.PayParam.appId,
        "timeStamp": this.PayParam.timeStamp,
        "nonceStr": this.PayParam.nonceStr,
        "package": this.PayParam.package,
        "signType": this.PayParam.signType,
        "paySign": this.PayParam.paySign
      }, function(res) {
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          // 支付成功
          Toast('支付成功');
          setTimeout(()=>{
            location.href="https://wx.homwee.net/#/afterPay";
          },3000)
        }else if(res.err_msg === "get_brand_wcpay_request:cancel"){
          Toast('支付失败');
          setTimeout(()=>{
            location.href="https://wx.homwee.net/#/afterPay";
          },1000)
        }
      });
    },
    paySDK() {
      this.payState=false
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          console.log("sdk1")
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        } else if (document.attachEvent) {
          console.log("sdk2")
          document.attachEvent('WeixinJSBridgeReady',this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady',this.onBridgeReady);
        }
      } else {
        console.log("sdk3")
        this.onBridgeReady();
      }
    },
    gotoPay:_.throttle(function(){
      this.payState=true
      this.listQuery={
        productId:'',
        faultTypeIds:'',
        chanel:'',
        amount:'',
        receiveName:'',
        receivePhone:'',
        receiveRegion:'',
        receiveAddress:''
      }
      let params;
      if(this.Imei===true){
        this.listQuery.productId=this.productId,
            this.listQuery.faultTypeIds=this.problemId,
            this.listQuery.amount=this.orderPrices,
            this.listQuery.receiveName=this.fixAddress,
            this.listQuery.receivePhone=this.phoneNum,
            this.listQuery.orderNo=this.orderNum,
            this.listQuery.customerShipment=this.sendNum,
            this.listQuery.receiveRegion=this.value3.replace(/\//g, ' '),
            this.listQuery.receiveAddress=this.longAddress
        this.listQuery.imei=this.ImeiNum
        if(this.value2==='其他'){
          this.listQuery.chanel=this.buyWay
        }else {
          this.listQuery.chanel=this.value2
        }
        params = JSON.parse(JSON.stringify(this.listQuery));
        for (var i in params) {
          if (!params[i]) {
            Toast("请输入必填信息！")
            return
          }
        }
      }else {
        this.listQuery.productId=this.productId,
            this.listQuery.faultTypeIds=this.problemId,
            this.listQuery.amount=this.orderPrices,
            this.listQuery.receiveName=this.fixAddress,
            this.listQuery.receivePhone=this.phoneNum,
            this.listQuery.orderNo=this.orderNum,
            this.listQuery.customerShipment=this.sendNum,
            this.listQuery.receiveRegion=this.value3.replace(/\//g, ' '),
            this.listQuery.receiveAddress=this.longAddress
        if(this.value2==='其他'){
          this.listQuery.chanel=this.buyWay
        }else {
          this.listQuery.chanel=this.value2
        }
        params = JSON.parse(JSON.stringify(this.listQuery))
        for (var a in params) {
          if (!params[a]) {
            Toast("请输入必填信息！")
            return
          }
        }
      }

      const token = localStorage.getItem('token');
      console.log('浏览器用户授权token为'+token)
      const urlSave = 'https://wdt.homwee.net/prod-api/wx/save'; // 替换成你的API地址
      // 创建一个对象来存储请求头信息
      const headers = {
        'wxToken': token // 替换成你的wxtoken值
      };
      // 使用Axios发送POST请求
      axios.post(urlSave, params, { headers })
          .then(response => {
            if(this.payCount===0){
              this.payCount=1
              this.PayParam.appId = response.data.data.jsPaymentInfo.appId
              this.PayParam.timeStamp = response.data.data.jsPaymentInfo.timeStamp
              this.PayParam.nonceStr = response.data.data.jsPaymentInfo.nonceStr
              this.PayParam.package = response.data.data.jsPaymentInfo.packageVal
              this.PayParam.signType = response.data.data.jsPaymentInfo.signType
              this.PayParam.paySign = response.data.data.jsPaymentInfo.paySign
              console.log(this.PayParam)
              // 请求成功处理逻辑
              console.log(response.data)
              this.wxNo = response.data.data.wxNo
              localStorage.setItem('wxNo', this.wxNo)
              Toast('正在前往支付，请稍等！')
              this.paySDK()
            }
          })
          .catch(error => {
            console.error(error);
            const status = error.response.status
            console.log(status)
            if(status===402){
              alert("登录信息过期，请重新登录！")
              this.$router.push('/logIn')
            }
          });
    },10000),
    pathgoLookOrder(){
      this.$router.push({
        name:'lookOrder'
      })
    },
    doShowFixPicker(){
      this.searchColumns1=this.columns1
      this.showFixPicker=true
    },
    inputSearchValue(){
      if (this.searchValue!== '') {
        console.log("开始查询")
        setTimeout(() => {
          this.searchColumns1 = this.columns1.filter(item => {
            return item.indexOf(this.searchValue.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.searchColumns1 =this.columns1;
      }
    },
    onSearch() {
      console.log(this.searchValue)
      if (this.searchValue!== '') {
        console.log("开始查询")
        setTimeout(() => {
          this.searchColumns1 = this.columns1.filter(item => {
            return item.indexOf(this.searchValue.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.searchColumns1 =this.columns1;
      }
    },
    onCancel() {
      Toast("清除查询")
    },
    onConfirm(value) {
      this.columns1=[];
      this.value = value;
      const filterId = this.Clock.filter(obj => obj.name === value);
      let findKey= filterId[0].id
      this.productId=findKey
      if(this.productId === 6){
        this.Imei=false
      }else {
        this.Imei=true
      }
      console.log('给后端的产品参数'+this.productId)
      const filterFix=this.allDate.filter(obj => obj.id === findKey)
      this.QueryPrice=filterFix[0].prices
      for (let i = 0; i <filterFix[0].prices.length ; i++) {
        this.columns1.push(filterFix[0].prices[i].name)
      }
      this.showProductPicker = false;
      this.value1=''
      this.orderPrices=0
    },
    onConfirm1() {
      this.value1=''
      console.log(this.checkedValue)
      if(this.checkedValue.length===0){
        this.showFixPicker = false;
      }else {
        this.problemId=''
        this.orderPrices=0
        for (let i = 0; i <this.checkedValue.length; i++) {
          let onePrice=this.QueryPrice.filter(obj => obj.name === this.checkedValue[i])
          if(i<this.checkedValue.length-1){
            this.problemId=this.problemId+onePrice[0].no+","
          }else if(i===this.checkedValue.length-1){
            this.problemId=this.problemId+onePrice[0].no
          }
          onePrice=parseInt(onePrice[0].price)
          this.orderPrices=this.orderPrices+onePrice
          if(i<this.checkedValue.length-1){
            this.value1=this.value1+this.checkedValue[i]+"/"
          }else if(i===this.checkedValue.length-1){
            this.value1=this.value1+this.checkedValue[i]
          }
        }
        console.log('给后端的故障参数为:'+this.productId)
        this.searchValue=''
        this.checkedValue=[]
        this.showFixPicker = false;
      }
    },
    onConfirm2(value) {
      this.value2 = value;
      if(this.value2==="其他") {
        this.buyWay=''
        this.storePutJudge=true
      }else {
        this.storePutJudge=false
      }
      this.showBuyPicker = false;
    },
    onConfirm3(values) {
      this.value3 = values
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
      this.showArea = false;
    },
    onBlur:_.debounce(function(){
      document.getElementById('all').scrollTop=0
      // window.scroll(0, 0)
    },500)
  },
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}
#putBox{
  font-size: 0.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#toOrder{
  margin-top: 5%;
  color: dodgerblue;
  margin-bottom:2%;
  margin-right:5%;
  align-self: flex-end;
  font-size: 0.4rem;
}
#priceInformation{
  background: white;
  font-size: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}
#address{
  background: white;
  font-size: 0.4rem;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}
.putPart{
  opacity: 0.8;
  height: 1.4rem;
  background:white !important;
  width: 100%;
  border-bottom: 1px #F2F2F2 solid;
  border-left:1px #F2F2F2 solid ;
  border-right: 1px #F2F2F2 solid;
  display: flex;
  align-items: center;
}
</style>

<style>
.van-field__label span{
  opacity: 0.5;
  background:whitesmoke !important;
  font-size: 15px;
  font-weight: bolder;
  color: black;
}
button.van-picker__confirm{
  color: dodgerblue;
}
</style>
