import Vue from 'vue'
import VueRouter from 'vue-router'
import Order from '@/views/Order'
import putInformation from '@/views/putInformation'
import lookOrder from '@/views/lookOrder'
import afterPay from '@/views/afterPay'
import logIn from '@/views/logIn'
Vue.use(VueRouter);
export default new VueRouter({
    routes:[
        {
            name:'logIn',
            path:'/logIn',
            component:logIn
        },
        {
            name:'putInformation',
            path:'/putInformation',
            component:putInformation
        },
        {
            name:'Order',
            path:'/Order',
            component:Order
        },
        {
            name:'lookOrder',
            path:'/lookOrder',
            component:lookOrder
        },
        {
            name:'afterPay',
            path:'/afterPay',
            component:afterPay
        },
        {
            path:"*",
            redirect:"/putInformation"
        },
        {
            path:"",
            redirect:"/putInformation"
        }
    ]
})
