<template>
  <div style="width: 100%;height: 100%;background:#F2F2F2;overflow: auto;" id="all">
    <div v-if="this.payStatus==='3'" style="height: 100%">
      <el-table
          v-loading="loading"
          style="width: 100%;height:100%">
      </el-table>
    </div>
   <div v-else>
      <div id="putBox">
        <div id="toOrder" @click="pathgoLookOrder">维修订单</div>
        <div style="background: white;width: 92%;display: flex;flex-direction: column;align-items: center">
          <div class="putPart">
            <div class="Name">产品型号</div>
            <div class="backInfo">{{prodClass}}</div>
          </div>
          <div class="putPart">
            <div class="Name">故障类型</div>
            <div class="backInfo">{{wroClass}}</div>
          </div>
          <div class="putPart">
            <div class="Name">购买渠道</div>
            <div class="backInfo">{{buyWay}}</div>
          </div>
          <div class="putPart" >
            <div class="Name">购买订单编号</div>
            <div class="backInfo">{{buyNum}}</div>
          </div>
          <div class="putPart" >
            <div class="Name">工厂快递单号</div>
            <div class="backInfo">{{sendNum}}</div>
          </div>
          <div class="putPart">
            <div class="Name">维修费用</div>
            <div class="backInfo">{{fixPay}}</div>
          </div>
          <div class="putPart">
            <div class="Name">支付状态</div>
            <div class="backInfo" v-if="payStatus==='1'">{{ payTime }}</div>
            <div v-else-if="payStatus==='0'" style="display: flex;height: 1rem;width: 70%;align-items: center;">
              <div class="backInfo"  style="opacity: 0.5">
                未支付
              </div>
              <button style="text-align: center;
                          background:dodgerblue;
                          height: 80%;
                          color: white;
                          border: transparent;
                          border-radius: 0.5rem;
                          font-size: 0.4rem;
                          margin-right:8%;
                          width: 100%;"
                      @click="gotoPay">去支付</button>
            </div>
            <div class="backInfo" v-else-if="payStatus==='-1'">订单已关闭</div>
            <div class="backInfo" v-else-if="payStatus==='-2'">订单已关闭</div>
          </div>
          <div class="putPart">
            <div class="Name">收货地址</div>
            <div class="backInfo" style="margin-top: 0.15rem;height: 1.2rem;font-size: 0.4rem">{{payerAddress}}</div>
          </div>
          <div class="putPart" style="border-bottom:1px #F2F2F2 solid">
            <div class="Name">维修编号</div>
            <div class="backInfo">{{fixNum}}</div>
          </div>
        </div>
        <!--        <div id="addressGet">-->
        <!--          <div id="getTop">-->
        <!--            <div style="font-size: 0.55rem;margin-left: 2%;font-weight: bold">请寄到以下售后地址</div>-->
        <!--            <div  @click="copyText" style="-->
        <!--          font-size: 0.4rem;-->
        <!--          display: flex;-->
        <!--          align-items: center;-->
        <!--          margin-right: 5%;-->
        <!--          color:dodgerblue;"-->
        <!--            >-->
        <!--              复制-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div id="getMain">-->
        <!--            <div id="address">-->
        <!--              {{fixAddress}}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div id="getBottom">-->
        <!--            <div id="bottomTip">请在回寄的商品包裹中，附上纸条详尽描述故障情况</div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
   </div>
  </div>
</template>
<script>
// import WeixinJSBridge from 'weixin-jsapi';
import { Toast } from 'vant';
import axios from "axios";
export default {
  name: "orDer",
  created() {
    this.wxNo=localStorage.getItem('wxNo')
    this.queryUrl='https://wdt.homwee.net/prod-api/wx/detail?wxNo='+this.wxNo
    this.getQuery()
  },
  data(){
    return{
      loading: true,
      payCount:0,
      PayParam:{
        appId: "",
        timeStamp: "",
        nonceStr: "",
        package: "",
        signType: "RSA",
        paySign:"",
      },
      queryUrl:'',
      wxNo:'',
      payTime:'',
      prodClass:'',
      wroClass:'',
      buyWay:'',
      buyNum:'',
      sendNum:'',
      fixPay:'',
      payStatus:'3',
      payerAddress: '',
      fixNum:'',
      fixAddress:'',
    }
  },
  methods:{
    onBridgeReady() {
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": this.PayParam.appId,
        "timeStamp": this.PayParam.timeStamp,
        "nonceStr": this.PayParam.nonceStr,
        "package": this.PayParam.package,
        "signType": this.PayParam.signType,
        "paySign": this.PayParam.paySign
      }, function(res) {
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          // 支付成功
          Toast('支付成功');
          setTimeout(()=>{
            location.href="https://wx.homwee.net/#/afterPay";
          },3000)
        }else if(res.err_msg === "get_brand_wcpay_request:cancel"){
          Toast('支付失败');
          setTimeout(()=>{
            location.href="https://wx.homwee.net/#/afterPay";
          },1000)
        }
      });
    },
    paySDK() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          console.log("sdk1")
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        } else if (document.attachEvent) {
          console.log("sdk2")
          document.attachEvent('WeixinJSBridgeReady',this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady',this.onBridgeReady);
        }
      } else {
        console.log("sdk3")
        this.onBridgeReady();
      }
    },
    getQuery(){
      const token = localStorage.getItem('token');
      // 创建一个对象来存储请求头信息
      const headers = {
        'wxToken': token // 替换成你的wxtoken值
      };
      axios.get(this.queryUrl, {headers})
          .then(response => {
            this.prodClass=response.data.data.productName
            let result = response.data.data.faultType.replace(/-?\d+/g, '');
            result =result.replace(/,/g, '/');
            this.wroClass=result
            this.buyWay=response.data.data.chanel
            this.buyNum=response.data.data.orderNo
            this.sendNum=response.data.data.factoryShipment
            this.fixPay=response.data.data.amount+'.00元'
            this.payTime=response.data.data.payTime
            if(this.payTime!==null){
              const date = new Date(this.payTime);
              const year = date.getFullYear();
              const month = date.getMonth() + 1; // getMonth() 返回的月份从0开始，所以需要加1
              const day = date.getDate();
              this.payTime = year + "年" + month + "月" + day+'日'+'-'+'支付';
            }
            this.payStatus=response.data.data.payState
            this.payerAddress=response.data.data.receiveName+' '+response.data.data.receivePhone+' '+response.data.data.receiveRegion+response.data.data.receiveAddress
            this.fixNum=response.data.data.wxNo
            this.fixAddress=response.data.data.sendToAddress
            this.PayParam.appId=response.data.data.jsPaymentInfo.appId
            this.PayParam.timeStamp=response.data.data.jsPaymentInfo.timeStamp
            this.PayParam.nonceStr=response.data.data.jsPaymentInfo.nonceStr
            this.PayParam.package=response.data.data.jsPaymentInfo.packageVal
            this.PayParam.signType=response.data.data.jsPaymentInfo.signType
            this.PayParam.paySign=response.data.data.jsPaymentInfo.paySign
            console.log(this.PayParam)
            // 请求成功时的处理
            console.log("拿到订单数据了"+JSON.stringify(response.data.data));
          })
          .catch(error => {
            console.error(error);
            const status = error.response.status
            console.log(status)
            if(status===402){
              alert("登录信息过期，请重新登录！")
              this.$router.push('/logIn')
            }
          });
    },
    gotoPay() {
      Toast("前往支付中，请稍等！")
      if(this.payCount===0){
        this.payCount=1
        this.paySDK()
      }
    },
    pathgoLookOrder(){
      this.$router.push({
        name:'lookOrder'
      })
    },
    copyText() {
      let text = this.fixAddress;
      const isSafari = navigator.userAgent.match(/iPad|iPhone|iPod|Macintosh/i)
      return new Promise((resolve) => {
        // iOS 才用现代的方法复制，因为微信小程序安卓 webview 上用这个方法会报错
        if (navigator.clipboard && isSafari) {
          navigator.clipboard
              .writeText(text)
              .then(() => {
                Toast('复制信息成功！')
                resolve(true)
              })
              .catch(() => {
                resolve(false)
              })
        } else {
          import('copy-to-clipboard').then(({ default: copy }) => {
            Toast('复制信息成功！')
            resolve(
                copy(text, {
                  format: 'text/plain',
                  message: '请点击确定按钮复制链接',
                })
            )
          })
        }
      })
    }
  }
}
</script>

<style scoped>
#putBox{
  opacity: 1;
  font-size: 0.16rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#toOrder{
  opacity: 1;
  margin-top: 5%;
  color: dodgerblue;
  margin-bottom:2%;
  margin-right:5%;
  align-self: flex-end;
  font-size: 0.4rem;
}
.Name{
  opacity: 0.5;
  display: flex;
  align-items: center;
  height: 1rem;
  font-size: 0.45rem;
  color: black;
  width: 40%;
}
.backInfo{
  opacity: 0.8;
  margin-top: 0.2rem;
  height: 0.7rem;
  width: 70%;
  overflow-x: auto;
  display: flex;
  font-size: 0.45rem;
  color: black;
}
.putPart{
  height: 1.6rem;
  background:white;
  width: 90%;
  border-top: 1px #F2F2F2 solid;
  opacity: 1;
  display: flex;
  align-items: center;
}
#addressGet{
  background: white;
  border: 1px #F2F2F2 solid;
  margin-top: 10%;
  width: 90%;
  display: flex;
  flex-direction: column;
}
#getTop{
  padding: 0.08rem 0 0.08rem 0;
  display: flex;
  justify-content: space-between;
}
#getMain{
  color: grey;
  border-top: 1px #F2F2F2 solid;
  border-bottom: 1px #F2F2F2 solid;
  padding: 0  2% 0 0;
  display: flex;
  flex-direction: column;
}
#bottomTip{
  color: red;
}
#address,#people,#phone,#bottomTip{
  line-height: 0.6rem;
  padding: 0.08rem 0 0.08rem 0;
  font-size: 0.375rem;
  margin-left: 3%
}
</style>
