<template>
  <div style="display: flex;flex-direction: column;align-items: center;">
    <div id="putBox">
      <div id="toOrder" @click="pathputOrder">自助报修</div>
      <div class="putPart" style="border-top: 1px gray solid">
        <div class="Tip" style="font-size: 0.5rem;font-weight: bolder;margin-top:3%;margin-bottom: 2%">维修订单列表</div>
        <div class="Tip" style="margin-bottom: 2%;font-size: 0.3rem">可以查看您的历史维修订单</div>
      </div>
    </div>
    <div class="content">
      <!--    表格数据-->
      <template>
        <el-table
            v-infinite-scroll
            v-loading="loading"
            @cell-dblclick="celldblclick"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 0.6)"
            :data="list"
            :header-cell-style="{background:'#eef1f6',fontSize: '15px',fontWeight:'bolder',color:'black'}">
          <el-table-column width="125" align="center"  prop="date" label="维修单号" show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="color: dodgerblue" @click="lookOrder(scope.$index, scope.row)">{{scope.row.wxNo}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center"  prop="name" label="产品型号">
            <template slot-scope="scope">
              <span>{{scope.row.productName}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center"  prop="address" label="故障类型" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.faultType}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center"  prop="status" label="状态">
            <template slot-scope="scope">
              <div v-if="scope.row.payState==='1'">已支付</div>
              <div v-else-if="scope.row.payState==='0'" @click.once="toPay(scope.$index, scope.row)" style="color: dodgerblue">未支付</div>
              <div v-else>已关闭</div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </div>
</template>

<script>
// import WeixinJSBridge from 'weixin-jsapi';
import { Toast } from 'vant';
import axios from "axios";
export default {
  name: "lookOrder",
  created() {
    this.getQuery()
  },
  data() {
    return {
      PayParam:{
        appId: "",
        timeStamp: "",
        nonceStr: "",
        package: "",
        signType: "RSA",
        paySign:"",
      },
      queryAllUrl:'https://wdt.homwee.net/prod-api/wx/list',
      list: [],
      loading: false,
    }
  },
  methods:{
    onBridgeReady() {
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": this.PayParam.appId,
        "timeStamp": this.PayParam.timeStamp,
        "nonceStr": this.PayParam.nonceStr,
        "package": this.PayParam.package,
        "signType": this.PayParam.signType,
        "paySign": this.PayParam.paySign
      }, function(res) {
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          // 支付成功
          Toast('支付成功');
          setTimeout(()=>{
            location.href="https://wx.homwee.net/#/afterPay";
          },3000)
        }else if(res.err_msg === "get_brand_wcpay_request:cancel"){
          Toast('支付失败');
          setTimeout(()=>{
            location.href="https://wx.homwee.net/#/afterPay";
          },1000)
        }
      });
    },
    paySDK() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          console.log("sdk1")
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        } else if (document.attachEvent) {
          console.log("sdk2")
          document.attachEvent('WeixinJSBridgeReady',this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady',this.onBridgeReady);
        }
      } else {
        console.log("sdk3")
        this.onBridgeReady();
      }
    },
    getQuery(){
      const token = localStorage.getItem('token');
      // 创建一个对象来存储请求头信息
      const headers = {
        'wxToken': token // 替换成你的wxtoken值
      };
      axios.get(this.queryAllUrl, {headers})
          .then(response => {
           console.log(response.data.data)
            var arrList=response.data.data
            for (let i = 0; i <arrList.length ; i++) {
              let result=arrList[i].faultType.replace(/-?\d+/g, '')
              result=result.replace(/,/g, '/')
              arrList[i].faultType=result
              console.log(arrList[i].faultType)
            }
            this.list=response.data.data
          })
          .catch(error => {
            // 请求失败时的处理
            console.error(error);
            const status = error.response.status
            console.log(status)
            if(status===402){
              alert("登录信息过期，请重新登录！")
              this.$router.push('/logIn')
            }
          });
    },
    pathputOrder(){
      this.$router.push({
        name:'putInformation'
      })
    },
    toPay(index,row){
      this.PayParam.appId=row.jsPaymentInfo.appId
      this.PayParam.timeStamp=row.jsPaymentInfo.timeStamp
      this.PayParam.nonceStr=row.jsPaymentInfo.nonceStr
      this.PayParam.package=row.jsPaymentInfo.packageVal
      this.PayParam.signType=row.jsPaymentInfo.signType
      this.PayParam.paySign=row.jsPaymentInfo.paySign
      console.log(this.PayParam)
      localStorage.setItem('wxNo', row.wxNo)
      Toast("正在前往支付页面，请稍等！")
      this.paySDK()
    },
    lookOrder(index,row){
      localStorage.setItem('wxNo', row.wxNo)
      this.$router.push({
        name:'Order'
      })
    },
    // eslint-disable-next-line no-unused-vars
    celldblclick (row, column, cell, event) {
      console.log(row)
      navigator.clipboard.writeText(JSON.stringify(row))
          .then(() => {
            this.onCopy()
          })
          .catch(() => {
          })
    },
    onCopy () {
      Toast("复制成功！")
    },
  }
}
</script>

<style scoped>
#putBox{
  font-size: 0.16rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Tip{
  margin-left: 0.5rem;
}
#toOrder{
  margin-top: 5%;
  color: dodgerblue;
  margin-bottom:2%;
  margin-right:5%;
  align-self: flex-end;
  font-size: 0.4rem;
}
.putPart{
  flex-direction: column;
  height: 1.4rem;
  width: 95%;
  opacity: 1;
  display: flex;
}
.content{
  margin-top: 4%;
  width: 98%;
}
</style>
