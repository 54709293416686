<template>
  <div style="width: 100%;height: 100%;background: #F2F2F2; overflow: auto;" id="all">
    <el-table
        v-loading="loading"
        style="width: 100%;height:100%">
    </el-table>
  </div>
</template>

<script>
import axios from "axios";
import {Toast} from "vant";

export default {
  name: "logIn",
  data(){
    return{
      loading:true,
    }
  },
  created() {
    this.getIdToken()
  },
  methods:{
    getIdToken(){
      localStorage.removeItem('token')
      const params = new URLSearchParams(window.location.search);
      let code = params.get('code');
      if(code===null){
        window.location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxfd586e01a7d57ee2&redirect_uri=https%3A%2F%2Fwx.homwee.net%2F%23%2FlogIn&response_type=code&scope=snsapi_base&state=wxfd586e01a7d57ee2#wechat_redirect'
      }else {
        const state = params.get('state');
        let tokenUrl='https://wdt.homwee.net/prod-api/wx/auth?code='+code+'&state='+state
        axios.get(tokenUrl)
            .then(response => {
              this.OpenIdToken=response.data.msg
              localStorage.setItem('token',this.OpenIdToken)
              Toast('身份授权成功')
              this.$router.push({
                name:'putInformation'
              })
            })
            .catch(error => {
              // 请求失败时的处理
              Toast("授权失败！请重试！")
              console.error(error);
            });
      }
    },
  }
}
</script>

<style scoped>

</style>
