<template>
  <div style="display:flex;flex-direction: column;align-items: center">
    <div style="margin-top: 30%;font-size: 0.8rem;color: gray">
      <div v-if="payState==='1'">支付成功</div>
      <div v-else>支付失败</div>
    </div>
    <van-button style=" margin-top:20%;
                  color: #F2F2F2;
                  background: dodgerblue;
                  margin-bottom:5%;
                  width: 60%;
                  border-radius: 1rem;
                  height: 1.2rem;
                  font-size:15px" @click="pathOrder">
      查看维修单
    </van-button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  created() {
    this.wxNo=localStorage.getItem('wxNo')
    this.queryUrl='https://wdt.homwee.net/prod-api/wx/detail?wxNo='+this.wxNo
    this.getQuery()
  },
  name: "afterPay",
  data(){
    return{
      queryUrl:'',
      wxNo:'',
      payState:'0',
    }
  },
  methods:{
    getQuery(){
      const token = localStorage.getItem('token');
      // 创建一个对象来存储请求头信息
      const headers = {
        'wxToken': token // 替换成你的wxtoken值
      };
      axios.get(this.queryUrl, {headers})
          .then(response => {
            // 请求成功时的处理
            this.payState=response.data.data.payState
          })
          .catch(error => {
            console.error(error);
            const status = error.response.status
            console.log(status)
            if(status===402){
              alert("登录信息过期，请重新登录！")
              this.$router.push('/logIn')
            }
          });
    },
    pathOrder(){
      this.$router.push({
        name:'Order'
      })
    },
  }
}
</script>

<style scoped>

</style>
